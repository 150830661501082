import React, { useState } from "react"
import Footer from "../components/footer"
import PageHero from "../components/pagehero"
import Styles from "../styles/components/ContactSection.module.scss"
import ContactForm from "../components/ContactForm"
import MobileHeader from "../components/MobileHeader"
import DownArrow from "../images/down-arrow.svg"
import UpArrow from "../images/up-arrow.svg"

const Contact = () => {
  const [menuActive, setMenuState] = useState(false)
  const [showForm, setFormState] = useState("none")
  return (
    <div>
      <MobileHeader></MobileHeader>
      <PageHero
        heading={[
          "Hey, Thanks for being here ",
          <span key="contact">We'll love to hear from you</span>,
        ]}
        description="We typically get back to you by email within the next 24 hours."
        imgSrc="/contact-bg.jpg"
        pageTitle="Contact"
      ></PageHero>
      <div className={`${Styles.container} container-padding`}>
        <div className={Styles["section-line--title"]}>
          <h5>Hello</h5>
        </div> 
        <div>
          <div>
            <div
              className={Styles.h1}
              onClick={() => setMenuState(!menuActive)}
            >
              <div>I'll like to talk about...</div>
              {menuActive ? <img src={UpArrow} alt="down arrow" /> :  <img src={DownArrow} alt="down arrow" />}
            </div>
            <div className={` ${Styles.collapsible} ${ menuActive ? Styles.active : ""
              }`}>
              <ul className={Styles.ul}>
                <li className={Styles.li}
                  onClick={() => {
                    if (menuActive) {
                      setMenuState(!menuActive)
                      return setFormState("workForm")
                    }
                  }}
                >
                  A New Project
                </li>
                <li className={Styles.li}
                  onClick={() => {
                    if (menuActive) {
                      setMenuState(!menuActive)
                      return setFormState("careerForm")
                    }
                  }}
                >
                  Working With PhosMobile{" "}
                </li>
                <li className={Styles.li}
                  onClick={() => {
                    if (menuActive) {
                      setMenuState(!menuActive)
                      return setFormState("generalForm")
                    }
                  }}
                >
                  Just Say Hello
                </li>
              </ul>
            </div>
            <ContactForm formType={showForm}></ContactForm>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default Contact
