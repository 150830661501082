import React from "react"
import Styles from "../styles/components/ContactSection.module.scss"

class WorkForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
    }
  }
  render() {
    const { status } = this.state
    return (
      <div>
        <h2 className={`${Styles.contactFormTitle}`}>New Project</h2>
        <form
          className={`${Styles.contactForm}`}
          name="New-Project-Form"
          method="POST"
          action="https://formspree.io/xqkyejqv"
        >
          <div for="Textinput" className={`${Styles.Textinput}`}>
            <input
              name="first_name"
              type="text"
              id="Textinput"
              placeholder="* First Name"
              required
            />
            <span className={`${Styles.label}`}>* First Name</span>
          </div>
          <div for="Textinput" className={`${Styles.Textinput}`}>
            <input
              name="last_name"
              type="text"
              id="Textinput"
              placeholder="* Last Name"
              required
            />
            <span className={`${Styles.label}`}>* Last Name</span>
          </div>
          <div for="Textinput" className={`${Styles.Textinput}`}>
            <input
              name="email"
              type="email"
              id="Textinput"
              placeholder="* Email"
              required
            />
            <span className={`${Styles.label}`}>* Email</span>
          </div>
          <div for="Textinput" className={`${Styles.Textinput}`}>
            <input
              name="phone_number"
              type="text"
              id="Textinput"
              placeholder="*Phone Number"
              required
            />
            <span className={`${Styles.label}`}>* Phone Number</span>
          </div>
          <div for="Textinput" className={`${Styles.Textinput}`}>
            <input
              name="company_name"
              type="text"
              id="Textinput"
              placeholder="*Company Name"
              required
            />
            <span className={`${Styles.label}`}>* Company Name</span>
          </div>
          <div for="Textinput" className={`${Styles.Textinput}`}>
            <input
              name="title_or_role"
              type="text"
              id="Textinput"
              placeholder="*Your Title/ Role"
              required
            />
            <span className={`${Styles.label}`}>* Your Title/ Role</span>
          </div>

          <div for="Textarea" className={`${Styles.Textarea}`}>
            <textarea
              rows="6"
              cols="50"
              placeholder="Please tell us a bit about what you are looking to collaborate on. Things  like scope, budget, timeline, and the issues you'd like to solve are helpful :) "
              id="Textarea"
              name="more_detail"
              required
            ></textarea>
            <span className={`${Styles.label}`}>
              * Please tell us a bit about what you're looking for
            </span>
          </div>
          {status === "SUCCESS" ? (
            <p>Thanks!</p>
          ) : (
            <button className={`${Styles.submitBtn}`}>Submit</button>
          )}
          {status === "ERROR" && <p>Ooops! There was an error.</p>}
        </form>
      </div>
    )
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }
}

class CareerForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
    }
  }
  render() {
    const { status } = this.state
    return (
      <div>
        <h2 className={`${Styles.contactFormTitle}`}>Work with Us</h2>
        <form
          className={`${Styles.contactForm}`}
          name="Working-With-Phosmobile-Form"
          method="POST"
          action="https://formspree.io/xknqoebb"
        >
          <div for="Textinput" className={`${Styles.Textinput}`}>
            <input
              name="first_name"
              type="text"
              id="Textinput"
              placeholder="* First Name"
              required
            />
            <span className={`${Styles.label}`}>* First Name</span>
          </div>
          <div for="Textinput" className={`${Styles.Textinput}`}>
            <input
              name="last_name"
              type="text"
              id="Textinput"
              placeholder="* Last Name"
              required
            />
            <span className={`${Styles.label}`}>* Last Name</span>
          </div>
          <div for="Textinput" className={`${Styles.Textinput}`}>
            <input
              name="email"
              type="email"
              id="Textinput"
              placeholder="* Email"
              required
            />
            <span className={`${Styles.label}`}>* Email</span>
          </div>
          <div for="Textinput" className={`${Styles.Textinput}`}>
            <input
              name="phone_number"
              type="text"
              id="Textinput"
              placeholder="* Phone Number"
              required
            />
            <span className={`${Styles.label}`}>* Phone Number</span>
          </div>
          <div for="Textinput" className={`${Styles.Textinput}`}>
            <input
              name="linkedin_profile"
              type="text"
              id="Textinput"
              placeholder="* LinkedIn Profile"
              
            />
            <span className={`${Styles.label}`}>* LinkedIn Profile</span>
          </div>
          <div for="Textinput" className={`${Styles.Textinput}`}>
            <input
              name="portfolio_url"
              type="text"
              id="Textinput"
              placeholder="*  Portfolio Url/Github Profile"
              required
            />
            <span className={`${Styles.label}`}>* Portfolio Url/Github Link</span>
          </div>

          <div for="Textarea" className={`${Styles.Textarea}`}>
            <textarea
              rows="6"
              cols="50"
              placeholder="Please tell us a bit about yourself... "
              id="Textarea"
              name="more_detail"
              required
            ></textarea>
            <span className={`${Styles.label}`}>* A bit about yourself </span>
          </div>

          {status === "SUCCESS" ? (
            <p>Thanks!</p>
          ) : (
            <button className={`${Styles.submitBtn}`}>Submit</button>
          )}
          {status === "ERROR" && <p>Ooops! There was an error.</p>}
        </form>
      </div>
    )
  }

  submitForm(ev) {
    ev.preventDefault()
    const form = ev.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        this.setState({ status: "SUCCESS" })
      } else {
        this.setState({ status: "ERROR" })
      }
    }
    xhr.send(data)
  }
}

class GeneralForm extends React.Component {
  constructor(props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      status: "",
    }
  }
  render() {
    const { status } = this.state
    return (
  <div>
    <h2 className={`${Styles.contactFormTitle}`}>Say Hello</h2>
    <form
      className={`${Styles.contactForm}`}
      name="Just-Hello-Form"
      method="POST"
      action="https://formspree.io/xvowzyrq"
    >
      <div for="Textinput" className={`${Styles.Textinput}`}>
        <input
          name="first_name"
          type="text"
          id="Textinput"
          placeholder="* First Name"
          required
        />
        <span className={`${Styles.label}`}>* First Name</span>
      </div>
      <div for="Textinput" className={`${Styles.Textinput}`}>
        <input
          name="last_name"
          type="text"
          id="Textinput"
          placeholder="* Last Name"
          required
        />
        <span className={`${Styles.label}`}>* Last Name</span>
      </div>
      <div for="Textinput" className={`${Styles.Textinput}`}>
        <input name="email" type="email" id="Textinput" placeholder="* Email" required/>
        <span className={`${Styles.label}`}>* Email</span>
      </div>
      <div for="Textinput" className={`${Styles.Textinput}`}>
        <input
          name="phone_number"
          type="text"
          id="Textinput"
          placeholder="*Phone Number"
          required
        />
        <span className={`${Styles.label}`}>* Phone Number</span>
      </div>
      <div for="Textinput" className={`${Styles.Textinput}`}>
        <input
          name="company_name"
          type="text"
          id="Textinput"
          placeholder="*Company Name"
        />
        <span className={`${Styles.label}`}>* Company Name</span>
      </div>
      <div for="Textinput" className={`${Styles.Textinput}`}>
        <input
          name="title_or_role"
          type="text"
          id="Textinput"
          placeholder="* Your Title/ Role"
        />
        <span className={`${Styles.label}`}>* Your Title/ Role</span>
      </div>

      <div for="Textarea" className={`${Styles.Textarea}`}>
        <textarea
          rows="6"
          cols="50"
          placeholder="Please tell us what you'd like to talk about "
          id="Textarea"
          name="more_details"
          required
        ></textarea>
        <span className={`${Styles.label}`}>
          * Please tell us how we can help you
        </span>
      </div>

      {status === "SUCCESS" ? (
            <p>Thanks!</p>
          ) : (
            <button className={`${Styles.submitBtn}`}>Submit</button>
          )}
          {status === "ERROR" && <p>Ooops! There was an error.</p>}
    </form>
  </div>
)}
submitForm(ev) {
  ev.preventDefault()
  const form = ev.target
  const data = new FormData(form)
  const xhr = new XMLHttpRequest()
  xhr.open(form.method, form.action)
  xhr.setRequestHeader("Accept", "application/json")
  xhr.onreadystatechange = () => {
    if (xhr.readyState !== XMLHttpRequest.DONE) return
    if (xhr.status === 200) {
      form.reset()
      this.setState({ status: "SUCCESS" })
    } else {
      this.setState({ status: "ERROR" })
    }
  }
  xhr.send(data)
}
}

const ContactForm = props => (
  <div>
    {props.formType == "workForm" ? <WorkForm></WorkForm> : ""}
    {props.formType == "careerForm" ? <CareerForm></CareerForm> : ""}
    {props.formType == "generalForm" ? <GeneralForm></GeneralForm> : ""}
  </div>
)

export default ContactForm
